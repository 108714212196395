import React from 'react';
import instaImage from '../../assets/images/insta.png';
import whatsappImage from '../../assets/images/whatsapp.png';
import Container from "../Container";
import {useTranslation} from "react-i18next";
import { Link } from 'react-router-dom';

const Footer = () => {
    const {t, i18n} = useTranslation('translation', {keyPrefix: 'footer'})
    return (
        <div className={`bg-[#393DA1] py-14 `}>
            <Container>
                <div className="grid xl:grid-cols-5 grid-cols-1 gap-y-12 text-white text-left select-none">
                    {/* Левая часть */}
                    <div className={`xl:col-span-3 flex flex-col gap-4`}>
                        <span className="text-[20px] md:text-[60px] font-bold">TANU.AI.PRO</span>
                        <span className="text-[#D2D2D2] font-[500] text-[12px] md:text-[24px] leading-[28.2px]">
                            {t('description')}</span>
                        <div className="flex items-center gap-4">
                            <Link to="https://www.instagram.com/tanu.ai.pro?igsh=emtwdzY2YWthZGxq" target='_blank'><img className="size-[30px] md:size-[40px]" src={instaImage}
                                             alt="Instagram"/></Link>
                            <Link to="https://api.whatsapp.com/send/?phone=77086864756&text=%D0%A1%D3%99%D0%BB%D0%B5%D0%BC%D0%B5%D1%82%D1%81%D1%96%D0%B7%D0%B1%D0%B5%21+Gallup+%D0%BA%D1%83%D1%80%D1%81%D1%8B+%D0%B6%D0%B0%D0%B9%D0%BB%D1%8B+%D1%82%D0%BE%D0%BB%D1%8B%D2%9B+%D0%B1%D1%96%D0%BB%D0%B3%D1%96%D0%BC+%D0%BA%D0%B5%D0%BB%D0%B5%D0%B4%D1%96%21&type=phone_number&app_absent=0" target='_blank'><img className="size-[30px] md:size-[40px]" src={whatsappImage} alt="WhatsApp"/></Link>
                            {/* <Link to="/" target='_blank'><img className="size-[30px] md:size-[40px]" src={vkImage} alt="VK"/></Link> */}
                        </div>
                    </div>
                    {/* Правая часть */}
                    <div
                        className="flex sm:flex-row w-full justify-start flex-col gap-4 gap-y-12 xl:col-span-2 text-[20px]">
                        <div className="flex flex-col gap-4 xl:basis-full basis-1/2">
                            <span className="font-bold">{t('linkSections.0')}</span>
                            <div
                                className="flex flex-col gap-4 md:text-[20px] text-[14px]">
                                <Link to="/"
                                   className={`block w-fit relative after:duration-300 after:block hover:after:w-full after:w-0 after:h-[1px] after:absolute after:bottom-0 after:bg-white`}>Создатели</Link>
                                <Link to="/"
                                   className={`block w-fit relative after:duration-300 after:block hover:after:w-full after:w-0 after:h-[1px] after:absolute after:bottom-0 after:bg-white`}>Почта</Link>
                                <Link to="/"
                                   className={`block w-fit relative after:duration-300 after:block hover:after:w-full after:w-0 after:h-[1px] after:absolute after:bottom-0 after:bg-white`}>Карьера</Link>
                                <Link to="/"
                                   className={`block w-fit relative after:duration-300 after:block hover:after:w-full after:w-0 after:h-[1px] after:absolute after:bottom-0 after:bg-white`}>Поддержка</Link>
                            </div>
                        </div>
                        <div className="flex flex-col gap-4 xl:basis-full basis-1/2">
                            <span className="font-bold text-[20px]">{t('linkSections.1')}</span>
                            <div
                                className="flex flex-col gap-4 md:text-[20px] text-[14px]">
                                <Link to="/" style={{transition: 'width 0.3s ease-in'}}
                                   className={`block w-fit relative after:duration-300 after:block hover:after:w-full after:w-0 after:h-[1px] after:absolute after:bottom-0 after:bg-white`}>Блог</Link>
                                <Link to="/" style={{transition: 'width 0.3s ease-in'}}
                                   className={`block w-fit relative after:duration-300 after:block hover:after:w-full after:w-0 after:h-[1px] after:absolute after:bottom-0 after:bg-white`}>Лицензия</Link>
                                <Link to="/" style={{transition: 'width 0.3s ease-in'}}
                                   className={`block w-fit relative after:duration-300 after:block hover:after:w-full after:w-0 after:h-[1px] after:absolute after:bottom-0 after:bg-white`}>Правила</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Footer;
