import React, {useEffect, useState} from 'react'
import '../../assets/css/main.css'
import {Link, useLocation, useNavigate} from 'react-router-dom';
import logoCircledCropped from '../../assets/images/logo_circled_cropped.png'
import Container from "../Container";
import Sidebar from "./Sidebar";
import {Bars3Icon} from "@heroicons/react/24/solid"
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {logout} from '../../actions/authActions.js';
import {fetchUserProfile} from "../../actions/profileActions";
import {fetchGallupList} from "../../actions/gallupActions";
import {login as performLogin} from "../../actions/authActions";

const Navbar = () => {
  const navigate = useNavigate();
  const {t, i18n} = useTranslation()
  const locationPath = useLocation().pathname;
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const dispatch = useDispatch()
  const profile = useSelector(state => state.profile.userProfile);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchUserProfile());
    }
  }, [dispatch]);

  useEffect(() => {
    const closeSidebar = (event) => {
      if (isSidebarOpen && !event.target.closest('.sidebar') && !event.target.closest('.sidebar-toggle')) {
        setIsSidebarOpen(false);
      }
    };

    document.addEventListener('click', closeSidebar);

    return () => {
      document.removeEventListener('click', closeSidebar);
    };
  }, [isSidebarOpen]);

  const handleLogin = () => {
    navigate('/login');
  };
  const handleLogout = () => {
    dispatch(logout())
    navigate('/');
    window.location.reload();
  }
  const handleToProfile = () => {
    navigate('/dashboard')
  }

  const locales = {
    ru: {title: 'RU'},
    en: {title: 'EN'},
    kz: {title: 'KZ'}
  }

  const switchLang = async (key) => {
    localStorage.setItem('savedLang', key);
    await i18n.changeLanguage(key)
  }

  const landingURLs = ['/', '/news', '/faq', '/login']
  const onLanding = landingURLs.includes(locationPath);

  return (
    <div className={`${locationPath === '/login' ? 'lg:bg-[#EAF8FC] bg-transparent' : 'bg-[#EAF8FC]'}`}>
      <Container>
        <div className="select-none hidden lg:flex flex-col gap-y-3 lg:flex-row justify-between w-full py-10">
          <div className="flex flex-row gap-12 items-center justify-between">
            <Link to="/" className={`flex flex-row gap-4 items-center`}>
              <img src={logoCircledCropped} className={`size-12`}/>
              <span className='font-extrabold text-[20px] break-keep text-nowrap'>TANU.AI.PRO</span>
            </Link>
            <div className={`flex justify-between flex-row gap-8 xl:gap-12 items-center`}>
              <Link to="/" className={`font-bold text-[16px] ${locationPath === '/' && 'text-[#393DA1] before:w-1/2'}
                relative before:absolute before:bottom-[-.15em] before:left-0 before:w-0 before:h-[.15em] before:bg-[#393DA1] before:transition-[width]
                before:duration-300 before:ease-out hover:before:w-1/2`}>{t("navbar.links.0")}</Link>
              <Link to="/faq" className={`font-bold text-[16px] ${locationPath === '/faq' && 'text-[#393DA1] before:w-1/2'}
                relative before:absolute before:bottom-[-.15em] before:left-0 before:w-0 before:h-[.15em] before:bg-[#393DA1] before:transition-[width]
                before:duration-300 before:ease-out hover:before:w-1/2`}>{t("navbar.links.1")}</Link>
              <Link to="/news" className={`font-bold text-[16px] ${locationPath === '/news' && 'text-[#393DA1] before:w-1/2'}
                relative before:absolute before:bottom-[-.15em] before:left-0 before:w-0 before:h-[.15em] before:bg-[#393DA1] before:transition-[width]
                before:duration-300 before:ease-out hover:before:w-1/2`}>{t("navbar.links.2")}</Link>
              {isLoggedIn && (
                <Link to="/dashboard" className={`font-bold text-[16px] ${locationPath === '/dashboard' && 'text-[#393DA1] before:w-1/2'}
                  relative before:absolute before:bottom-[-.15em] before:left-0 before:w-0 before:h-[.15em] before:bg-[#393DA1] before:transition-[width]
                  before:duration-300 before:ease-out hover:before:w-1/2`}>{t("navbar.links.3")}</Link>
              )}
              {(isLoggedIn && profile && (profile.role == 'Тану Админ' || profile.role == 'Админ Профориентатор' )) && (
                <Link to="/admin" className={`font-bold text-[16px] ${locationPath === '/admin' && 'text-[#393DA1] before:w-1/2'}
                  relative before:absolute before:bottom-[-.15em] before:left-0 before:w-0 before:h-[.15em] before:bg-[#393DA1] before:transition-[width]
                  before:duration-300 before:ease-out hover:before:w-1/2`}>Admin Panel</Link>
              )}
            </div>
          </div>
          <div>
            <div className="flex flex-row gap-6 justify-between items-center">
              <div className="flex flex-row gap-4">
                {Object.keys(locales).map(locale => (
                  <button key={locale} className={`font-bold text-[16px] text-black
                    ${i18n.resolvedLanguage === locale ? 'text-black' : 'text-black/50'}`}
                    onClick={() => switchLang(locale)}>{locales[locale].title}</button>
                ))}
              </div>
              <button onClick={isLoggedIn ?
                onLanding ? handleToProfile : handleLogout
                : handleLogin}
                className={`transition-all rounded-full font-semibold leading-[18.8px] text-[16px] text-white
                ${onLanding ? 'bg-[#393DA1] hover:bg-[#393DA1]/80' : 'bg-red-600 hover:bg-red-600/80'}
                ${(isLoggedIn && onLanding) ? 'ps-1 py-1 pe-4' : 'px-12 py-4'}`}>
                {isLoggedIn ?
                  onLanding ?
                    <div className={`flex flex-row size-full gap-2`}>
                      <div className={`rounded-full size-[45px] min-w-[45px] min-h-[45px] bg-white grid place-items-center`}>
                        <img src="" alt=""/>
                      </div>
                      <div className={`flex items-center`}>{profile && profile.name}</div>
                    </div>
                    : t("navbar.button.logout")
                  : t("navbar.button.login")}
              </button>
            </div>
          </div>
        </div>
        <div className="flex lg:hidden justify-center relative items-center py-5">
          <div className="sidebar-toggle absolute left-0" onClick={toggleSidebar}>
            <Bars3Icon className={`text-black size-6`}></Bars3Icon>
          </div>
          <Sidebar isOpen={isSidebarOpen} loggedIn={isLoggedIn} onLanding={onLanding} toLogin={handleLogin}
            toProfile={handleToProfile} logout={handleLogout}/>
          <Link className="flex items-center gap-2 " to={'/'}>
            <img src={logoCircledCropped} className={`size-7`} alt={'logo'}/>
            <span className='font-bold tracking-wide text-lg break-keep text-nowrap'>TANU.AI.PRO</span>
          </Link>
          <div className={`absolute right-0 cursor-pointer ${isLoggedIn ? 'block' : 'hidden'}`} onClick={handleToProfile}>
            <div className="size-[40px] rounded-full bg-white">
              <img src="" alt="" className={`w-full object-cover`}/>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Navbar;