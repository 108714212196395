import React, { useState, useEffect, useRef } from 'react';
import '../../../assets/css/main.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { fetchReports } from '../../../actions/reportActions';
import { fetchGallupById } from '../../../actions/gallupActions';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import Loader from "../layout/Loader";

const ReportForm = () => {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'dashboard.userCard' });
  const { reportId, gallupId } = useParams();

  const [gallupUrl, setGallupUrl] = useState('');
  const [pdfLoaded, setPdfLoaded] = useState(false);
  const [isBusiness, setIsBusiness] = useState(false);
  const [showRegenerateButton, setShowRegenerateButton] = useState(false); 
  const [isInIframe, setIsInIframe] = useState(false);

  const initialFetch = useRef(true);

  const nextReportId = parseInt(reportId) + 1;
  const prevReportId = parseInt(reportId) - 1;

  useEffect(() => {
      setIsInIframe(window.self !== window.top);
  }, []);

  useEffect(() => {
    initialFetch.current = true;
  }, [nextReportId, prevReportId])

  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const reports = useSelector(state => state.report.reports);
  const error = useSelector(state => state.report.error);
  const gallupData = useSelector(state => state.gallup.gallupObject);

  const gallupLoading = useSelector(state => state.gallup.loading);
  const reportLoading = useSelector(state => state.report.loading);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const mobileDevice = window.innerWidth < 500;

  useEffect(() => {
    setGallupUrl('');
    setPdfLoaded(false);
    setShowRegenerateButton(false);
  }, [reportId, gallupId]);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    } else {
      dispatch(fetchGallupById(gallupId));
    }
  }, [gallupId, isLoggedIn, navigate, dispatch]);
  useEffect(() => {
    if (!gallupData) return;

    if (initialFetch.current) {
      initialFetch.current = false;
      setIsBusiness(gallupData.Business);
      
      const fetchData = async () => {
        await dispatch(fetchReports(gallupId, reportId, gallupData.Business));
        setShowRegenerateButton(true);
      };
      
      fetchData();
    }
  }, [gallupData, gallupId, reportId, dispatch]);

  useEffect(() => {
    if (reports && error === null) {
      setGallupUrl(`${reports}?timestamp=${new Date().getTime()}`);
    }
  }, [reports, error]);

  const regenerateReport = () => {
    setShowRegenerateButton(false); 
    setPdfLoaded(false);
    initialFetch.current = true;
    dispatch(fetchReports(gallupId, reportId, isBusiness, true)).then(() => {
      setShowRegenerateButton(true);
    });
  };

  return (
    <div className="bg-[#eaf8fc] min-h-screen">
      <div className="container-dashboard mx-auto flex flex-col xl:flex-col gap-4 pb-4">
        <div className='flex md:justify-between flex-wrap md:flex-nowrap align-center justify-center gap-3 md:gap-none'>
          {prevReportId >= 1 ? (
            <button
              className='bg-[#393DA1] p-2 rounded-[10px] px-20 py-3 text-[16px] leading-[18.8px] text-white '
              onClick={() => {
                if (prevReportId === 4) {
                  navigate(`/spheres/${gallupId}`);
                } else {
                  navigate(`/reports/${prevReportId}/${gallupId}`);
                }
              }}
            >
              Предыдущий отчет
            </button>
          ) : (
            <button
              className='bg-[#393DA1] p-2 rounded-[10px] px-20 py-3 text-[16px] leading-[18.8px] text-white'
              onClick={() => {
                navigate(`/profile/${gallupId}`);
              }}
            >
              Вернуться назад
            </button>
          )}

          {showRegenerateButton && (
            <button
              className='bg-green-800 p-2 rounded-[10px] px-20 py-3 text-[16px] leading-[18.8px] text-white'
              onClick={regenerateReport}
            >
              Сгенерировать заново
            </button>
          )}

          {nextReportId <= 4 ? (
            <button
              className='bg-[#393DA1] p-2 rounded-[10px] px-20 py-3 text-[16px] leading-[18.8px] text-white'
              onClick={() => {
                navigate(`/reports/${nextReportId}/${gallupId}`);
              }}
            >
              Следующий отчет
            </button>
          ) : nextReportId === 5 ? (
            <button
              className='bg-[#393DA1] p-2 rounded-[10px] px-20 py-3 text-[16px] leading-[18.8px] text-white'
              onClick={() => {
                navigate(`/spheres_new/${gallupId}`);
              }}
            >
              Страница сфер
            </button>
          ) : nextReportId <= 6 ? (
            <button
              className='bg-[#393DA1] p-2 rounded-[10px] px-20 py-3 text-[16px] leading-[18.8px] text-white'
              onClick={() => {
                navigate(`/reports/${nextReportId}/${gallupId}`);
              }}
            >
              Следующий отчет
            </button>
          ) : (
            <button
              className='bg-[#393DA1] p-2 rounded-[10px] px-20 py-3 text-[16px] leading-[18.8px] text-white'
              onClick={() => {
                navigate(`/profile/${gallupId}`);
              }}
            >
              Завершить сеанс
            </button>
          )}
        </div>
        {
          (!pdfLoaded) && 
            <Loader />
        }
        {(gallupLoading || reportLoading) ? <Loader /> : (
          (!isInIframe) && (
            <iframe 
              src={gallupUrl}
              onError={(e) => notification['error']({
                message: 'Возникла ошибка при загрузке отчета'
              })} 
              onLoad={() => setPdfLoaded(true)}
              width="100%" 
              height={mobileDevice ? '300px' : '800px'} 
            />
          )
        )}
      </div>
    </div>
  );
}

export default ReportForm;
