import axios from "axios";
import {API_URL} from "./constants";

const login = (username, password) => {
	const formData = new FormData();
	formData.append('username', username);
	formData.append('password', password);

	return axios
		.post(API_URL + 'auth/users/tokens', formData)
		.then((response) => {
			localStorage.setItem('access_token', response.data.access_token);
			return response.data;
		});
};

const loginRefresh = () => {
	let refreshToken = localStorage.getItem('refresh_token');

	return axios
		.post(API_URL + "refresh", {
			refresh: refreshToken,
		})
		.then((response) => {
			localStorage.setItem('access_token', response.data.access);
			return response.data;
		});
};

const register = (login, password1, password2, firstName, lastName) => {
	return axios
		.post(API_URL + "signup", {
			email: login,
			password1: password1,
			password2: password2,
			first_name: firstName,
			last_name: lastName,
		})
		.then((response) => {
			return response.data;
		});
};

const registerUser = (body) => {
	return axios.
		post(API_URL + 'auth/users', body)
		.then((res) => {
			return res;
		}).catch((error) => {
			return error;	
		})
}

const logout = () => {
	localStorage.removeItem("access_token");
	localStorage.removeItem("refresh_token");
};

export default {
	login,
	loginRefresh,
	logout,
	register,
	registerUser
};
