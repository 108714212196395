import React, {useEffect, useState} from 'react';
import mapAsset from '../../assets/images/mapAsset.png';
import '../../assets/css/map.css';
import ReviewCard from "./ReviewCard";
import reviewAvatar from '../../assets/images/map-avatar.png'
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';
import {useTranslation} from "react-i18next";

import Africa from '../../assets/images/africa_1.png'
import USA from '../../assets/images/USA.png'
import RU from '../../assets/images/RU.png'
import KZ from '../../assets/images/KZ.png'
import Greenland from '../../assets/images/Greenland.png'
import Australia from '../../assets/images/Australia.png'

const InteractiveMap = () => {
	const {t, i18n} = useTranslation('translation', {keyPrefix: 'main.interactiveMap'})
	const [isReviewCardShown, setIsReviewCardShown] = useState(false);
	const [reviewCardLeft, setReviewCardLeft] = useState(0);
	const [reviewCardTop, setReviewCardTop] = useState(0);
	const [currentReview, setCurrentReview] = useState(0);
	const reviews = [
		{
			avatar: reviewAvatar,
			name: 'Finlay Kirk',
			job: 'Web Developer',
			review: `От всей души благодарю Вас за ваши вложенные силы, терпение, время и знания❤️
					Мне очень понравились наши встречи❤️ 
					Продуктивно все проходило, я прям с большим желанием ждала наши индивидуальные занятия !
					Ни разу после встречи по видео не пожалела, что прошла именно этот курс!`
		},
		{
			avatar: reviewAvatar,
			name: 'Беннедикт Киберскотч',
			job: 'Web Developer',
			review: `Получила огромное удовольствие от прохождения курса. Мне очень понравилось быть вновь ученицей. Не думала, что этот процесс может быть настолько интересным и увлекательным. Материалы грамотно структурированы, а тренеры объясняют все доступным языком. Это был бесценный опыт. Я оценила работу группы и возможность общаться и обмениваться идеями с другими коллегами. Это было очень продуктивно.`
		},
		{
			avatar: reviewAvatar,
			name: 'Finlay Kirk',
			job: 'Web Developer',
			review: `TanuAI ұжымына шын жүректен алғыс және биік белестер тілеймін. Жасап жатырған жұмыстарыңыз бүкіл әлем үшін елеулі жобаға айналып, адамдарлы бақытты қыла беріңіздер!`
		},
		{
			avatar: reviewAvatar,
			name: 'Finlay Kirk',
			job: 'Web Developer',
			review: `TanuAI командасына сапалы контент, қолдау, мотивация үшін алғысымды білдіремін. Курсты жаңадан бастаушыларға, кәсіпкерлерге, осы тақырыпқа қызығатындардың барлығына ұсынамын!😌`
		},
		{
			avatar: reviewAvatar,
			name: 'Finlay Kirk',
			job: 'Web Developer',
			review: `“Сайт для профориентации – это просто замечательно! С помощью тестов я наконец-то понял, в какой сфере мне лучше всего развиваться”`
		},
		{
			avatar: reviewAvatar,
			name: 'Finlay Kirk',
			job: 'Web Developer',
			review: `Глубже поняла себя и нашла ответ на свой внутренний запрос, в том числе на вопрос о низкой адаптивности. Узнала больше о рисках своих талантов и о том, как работать, опираясь на сильные стороны. Это помогло мне лучше понять себя. Получила рекомендации по книгам, и на этой встрече было много полезной и интересной информации.`
		},
		{
			avatar: reviewAvatar,
			name: 'Finlay Kirk',
			job: 'Web Developer',
			review: `Консультация керемет өтті !барлығы ұнады ,сізбен сөйлескенде, мен өзіме қандай мамандықтар сәйкес келетінін және қандай бағыттарда ойлану керектігін түсіндім. Бұл мен үшін өте пайдалы болды, және менің болашақ жоспарларымды жасауға көмектесті. Сіздің уақытыңызды бөлгеніңіз үшін рахмет!`
		},
	]


	// позиционирует карточку правильно
	const positionCard = (event) => {
		// определяет местоположение курсора и карты
		const mapWrapper = document.querySelector('.map-wrapper')
		let cursorX = event.clientX,
			cursorY = event.clientY;
		let wrapperLeft = mapWrapper.getBoundingClientRect().left,
			wrapperTop = mapWrapper.getBoundingClientRect().top,
			wrapperRight = mapWrapper.getBoundingClientRect().right,
			wrapperBottom = mapWrapper.getBoundingClientRect().bottom;

		// определяет, нужно ли инвертировать позицию карточки относительно курсора, и устанавливает её
		const reviewCard = document.querySelector(".reviewCard")
		const cardWidth = reviewCard.getBoundingClientRect().width
		const cardHeight = reviewCard.getBoundingClientRect().height
		let cardPositionX = cursorX - wrapperLeft,
			cardPositionY = cursorY - wrapperTop
		if (cursorX + 1.1 * cardWidth > wrapperRight || cursorX + 1.1 * cardWidth > window.innerWidth) {
			setReviewCardLeft(cardPositionX - cardWidth - 15);
		} else setReviewCardLeft(cardPositionX + 15);
		if (cursorY + 1.3 * cardHeight > wrapperBottom || cursorY + 1.3 * cardHeight > window.innerHeight) {
			setReviewCardTop(cardPositionY - cardHeight - 15);
		} else setReviewCardTop(cardPositionY + 15);
	}

	const handleMouseEnter = async (index, event) => {
		// Получает индекс кружочка, на который навелись
		setCurrentReview(index)
		// позиционирует сразу же после наведения
		positionCard(event)
		// показывает карточку с задержкой, чтобы она успела переместиться после отработки handleMouseMove
		setTimeout(() => {
			setIsReviewCardShown(true)
		}, 200)
	}

	const handleMouseMove = (event) => {
		event.stopPropagation()
		event.nativeEvent.stopPropagation()
		positionCard(event)
	}

	const handleMouseOut = () => {
		// скрывает карточку
		setIsReviewCardShown(false);
	}

	useEffect(() => {
		const swiperMap = new Swiper('.swiper-map', {
			// Optional parameters
			loop: true,
			slidesPerView: 1,
			allowTouchMove: true,
			navigation: {
				nextEl: '.buttonNext-map',
				prevEl: '.buttonPrev-map',
			},
			autoplay: {
				delay: 5000
			}
		});
	}, []);

	return (
		<div className={`w-full md:py-[5em] pt-[2em] pb-[5em]`}>
			<div
				className="relative flex flex-col gap-8 md:gap-20 w-full lg:max-w-[1600px] mx-auto">
				<div className='flex flex-col gap-4 md:gap-8 px-4 select-none'>
					<div
						className='font-bold text-[18px] md:text-[40px] leading-[21px] md:leading-[47px] text-center text-[#00031E] md:text-[#1B1818]'>
						{t('title')}
					</div>
					<p className='text-center text-[12px] md:text-[20px] leading-[14px] md:leading-[23.5px] text-[#616161]'>
						{t('description')}
					</p>
					<span
						className={`md:block mx-auto hidden w-fit text-[#393DA1] text-[20px]`}>
                        {t('hint')}</span>
				</div>
				<div className='relative md:overflow-hidden'>
					<img className='relative w-full pointer-events-none select-none' src={mapAsset}/>
					<div className={`map-wrapper w-full h-full absolute inset-0 md:block hidden`}
							 onMouseEnter={() => setIsReviewCardShown(false)}
							 onMouseMove={() => setIsReviewCardShown(false)}>
						<div onMouseEnter={(e) => handleMouseEnter(0, e)} onMouseMove={handleMouseMove} onMouseOut={handleMouseOut}
								 className={`animate-pulse reviewCircle absolute shadow-sm cursor-pointer ring-[3px]
								 ring-[#393DA1] rounded-full size-[2.5em] overflow-hidden hover:scale-[130%]
								 hover:shadow-2xl hover:animate-none hover:ring-white transition-all duration-500 bottom-[65%] left-[16%]`}>
							<img src={USA} alt="" className={`h-full object-cover`}/>
						</div>
						<div onMouseEnter={(e) => handleMouseEnter(1, e)} onMouseMove={handleMouseMove} onMouseOut={handleMouseOut}
								 className={`animate-pulse reviewCircle absolute shadow-sm cursor-pointer ring-[3px]
								 ring-[#393DA1] rounded-full size-[2.5em] overflow-hidden hover:scale-[130%]
								 hover:shadow-2xl hover:animate-none hover:ring-white transition-all duration-500 bottom-[25%] left-[29%]`}>
							<img src={USA} alt="" className={`h-full object-cover`}/>
						</div>
						<div onMouseEnter={(e) => handleMouseEnter(2, e)} onMouseMove={handleMouseMove} onMouseOut={handleMouseOut}
								 className={`animate-pulse reviewCircle absolute shadow-sm cursor-pointer ring-[3px]
								 ring-[#393DA1] rounded-full size-[2.5em] overflow-hidden hover:scale-[130%]
								 hover:shadow-2xl hover:animate-none hover:ring-white transition-all duration-500 xl:bottom-[90%] bottom-[87%] left-[33%]`}>
							<img src={Greenland} alt="" className={`h-full object-cover`}/>
						</div>
						<div onMouseEnter={(e) => handleMouseEnter(3, e)} onMouseMove={handleMouseMove} onMouseOut={handleMouseOut}
								 className={`animate-pulse reviewCircle absolute shadow-sm cursor-pointer ring-[3px]
								 ring-[#393DA1] rounded-full size-[2.5em] overflow-hidden hover:scale-[130%]
								 hover:shadow-2xl hover:animate-none hover:ring-white transition-all duration-500
								 bottom-[40%] left-[51%]`}>
							<img src={Africa} alt="" className={`h-full object-cover`}/>
						</div>
						<div onMouseEnter={(e) => handleMouseEnter(4, e)} onMouseMove={handleMouseMove} onMouseOut={handleMouseOut}
								 className={`animate-pulse reviewCircle absolute shadow-sm cursor-pointer ring-[3px]
								 ring-[#393DA1] rounded-full size-[2.5em] overflow-hidden hover:scale-[130%]
								 hover:shadow-2xl hover:animate-none hover:ring-white transition-all duration-500 bottom-[72%] left-[58%]`}>
							<img src={RU} alt="" className={`h-full object-cover`}/>
						</div>
						<div onMouseEnter={(e) => handleMouseEnter(5, e)} onMouseMove={handleMouseMove} onMouseOut={handleMouseOut}
								 className={`animate-pulse reviewCircle absolute shadow-sm cursor-pointer ring-[3px]
								 ring-[#393DA1] rounded-full size-[2.5em] overflow-hidden hover:scale-[130%]
								 hover:shadow-2xl hover:animate-none hover:ring-white transition-all duration-500 bottom-[64%] xl:bottom-[65%] left-[70%]`}>
							<img src={KZ} alt="" className={`h-full object-cover object-center`}/>
						</div>
						<div onMouseEnter={(e) => handleMouseEnter(6, e)} onMouseMove={handleMouseMove} onMouseOut={handleMouseOut}
								 className={`animate-pulse reviewCircle absolute shadow-sm cursor-pointer ring-[3px]
								 ring-[#393DA1] rounded-full size-[2.5em] overflow-hidden hover:scale-[130%]
								 hover:shadow-2xl hover:animate-none hover:ring-white transition-all duration-500 bottom-[76%] xl:bottom-[78%] left-[75%]`}>
							<img src={RU} alt="" className={`h-full object-cover`}/>
						</div>
						{/* <div onMouseEnter={(e) => handleMouseEnter(7, e)} onMouseMove={handleMouseMove} onMouseOut={handleMouseOut}
								 className={`animate-pulse reviewCircle absolute shadow-sm cursor-pointer ring-[3px]
								 ring-[#393DA1] rounded-full size-[2.5em] overflow-hidden hover:scale-[130%]
								 hover:shadow-2xl hover:animate-none hover:ring-white transition-all duration-500 bottom-[20%] left-[82%]`}>
							<img src={Australia} alt="" className={`h-full object-cover`}/>
						</div> */}

						{/*кружочки из макета*/}
						{/*<div onMouseEnter={handleMouseEnter} onMouseMove={handleMouseMove} onMouseOut={handleMouseOut}*/}
						{/*     className={`animate-pulse hover:animate-none reviewCircle absolute bg-white hover:bg-[#393DA1] hover:ring-white transition-all duration-500 cursor-pointer ring-[3px] ring-[#393DA1] rounded-full size-[18px]*/}
						{/*bottom-[35%] left-[29%]`}></div>*/}
						{/*<div onMouseEnter={handleMouseEnter} onMouseMove={handleMouseMove} onMouseOut={handleMouseOut}*/}
						{/*     className={`animate-pulse hover:animate-none reviewCircle absolute bg-white hover:bg-[#393DA1] hover:ring-white transition-all duration-500 cursor-pointer ring-[3px] ring-[#393DA1] rounded-full size-[11px]*/}
						{/*bottom-[20%] left-[30%]`}></div>*/}
						{/*<div onMouseEnter={handleMouseEnter} onMouseMove={handleMouseMove} onMouseOut={handleMouseOut}*/}
						{/*     className={`animate-pulse hover:animate-none reviewCircle absolute bg-white hover:bg-[#393DA1] hover:ring-white transition-all duration-500 cursor-pointer ring-[3px] ring-[#393DA1] rounded-full size-[11px]*/}
						{/*bottom-[49%] left-[47%]`}></div>*/}
						{/*<div onMouseEnter={handleMouseEnter} onMouseMove={handleMouseMove} onMouseOut={handleMouseOut}*/}
						{/*     className={`animate-pulse hover:animate-none reviewCircle absolute bg-white hover:bg-[#393DA1] hover:ring-white transition-all duration-500 cursor-pointer ring-[3px] ring-[#393DA1] rounded-full size-[13px]*/}
						{/*bottom-[34%] left-[55%]`}></div>*/}
						{/*<div onMouseEnter={handleMouseEnter} onMouseMove={handleMouseMove} onMouseOut={handleMouseOut}*/}
						{/*     className={`animate-pulse hover:animate-none reviewCircle absolute bg-white hover:bg-[#393DA1] hover:ring-white transition-all duration-500 cursor-pointer ring-[3px] ring-[#393DA1] rounded-full size-[18px]*/}
						{/*bottom-[45%] left-[55%]`}></div>*/}
						{/*<div onMouseEnter={handleMouseEnter} onMouseMove={handleMouseMove} onMouseOut={handleMouseOut}*/}
						{/*     className={`animate-pulse hover:animate-none reviewCircle absolute bg-white hover:bg-[#393DA1] hover:ring-white transition-all duration-500 cursor-pointer ring-[3px] ring-[#393DA1] rounded-full size-[18px]*/}
						{/*bottom-[75%] left-[59%]`}></div>*/}
						{/*<div onMouseEnter={handleMouseEnter} onMouseMove={handleMouseMove} onMouseOut={handleMouseOut}*/}
						{/*     className={`animate-pulse hover:animate-none reviewCircle absolute bg-white hover:bg-[#393DA1] hover:ring-white transition-all duration-500 cursor-pointer ring-[3px] ring-[#393DA1] rounded-full size-[11px]*/}
						{/*bottom-[64%] left-[73%]`}></div>*/}
						{/*<div onMouseEnter={handleMouseEnter} onMouseMove={handleMouseMove} onMouseOut={handleMouseOut}*/}
						{/*     className={`animate-pulse hover:animate-none reviewCircle absolute bg-white hover:bg-[#393DA1] hover:ring-white transition-all duration-500 cursor-pointer ring-[3px] ring-[#393DA1] rounded-full size-[13px]*/}
						{/*bottom-[77%] left-[76%]`}></div>*/}
						{/*<div onMouseEnter={handleMouseEnter} onMouseMove={handleMouseMove} onMouseOut={handleMouseOut}*/}
						{/*     className={`animate-pulse hover:animate-none reviewCircle absolute bg-white hover:bg-[#393DA1] hover:ring-white transition-all duration-500 cursor-pointer ring-[3px] ring-[#393DA1] rounded-full size-[16px]*/}
						{/*bottom-[78%] left-[83%]`}></div>*/}
					</div>

					<ReviewCard isShown={isReviewCardShown} positionLeft={reviewCardLeft} positionTop={reviewCardTop}
											name={reviews[currentReview].name} job={reviews[currentReview].job}
											image={reviews[currentReview].avatar} review={reviews[currentReview].review}
											inCarousel={false}/>

					{/* слайдер (только для мобилок) */}
					<div className={`w-full absolute inset-0 md:hidden overflow-x-hidden`}>
						<div className="swiper-map h-full">
							<div className="swiper-wrapper h-full">
								{
									reviews.map((review, index) => {
										return (
											<div
												className={`swiper-slide`}
												key={index}>
												<div
													className="w-full h-full grid place-items-center xs:px-[4em] px-[1em]">
													<ReviewCard isShown={true}
																			name={review.name} job={review.job}
																			image={review.avatar} review={review.review}
																			inCarousel={true}/>
												</div>
											</div>)
									})
								}
							</div>

							{/* Navigation */}
							<div
								className={`xs:flex hidden flex-row justify-between w-full absolute top-1/2 -translate-y-1/2 z-[10] px-4`}>
								<div
									className={`grid buttonPrev-map size-[37px] rounded-[5px] ring-[#585858] ring-[1.5px] bg-white/50 place-items-center`}>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
											 strokeWidth={1}
											 stroke="currentColor" className="size-[30px] text-[#585858]">
										<path strokeLinecap="round" strokeLinejoin="round"
													d="M15.75 19.5 8.25 12l7.5-7.5"/>
									</svg>
								</div>
								<div
									className={`grid buttonNext-map size-[37px] rounded-[5px] ring-[#585858] ring-[1.5px] bg-white/50 place-items-center`}>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
											 strokeWidth={1}
											 stroke="currentColor" className="size-[30px] text-[#585858]">
										<path strokeLinecap="round" strokeLinejoin="round"
													d="m8.25 4.5 7.5 7.5-7.5 7.5"/>
									</svg>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default InteractiveMap;
